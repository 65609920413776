/* line 16, scss/70-modules/navigation/_module.scss */
.m-primary-nav {
	font-size: 1em;
	font-weight: 600;
	position: relative;
	display: block;
	vertical-align: top;
	max-height: 30px;
	letter-spacing: normal;
}

@media only screen and (max-width: 47.999em) {
	/* line 16, scss/70-modules/navigation/_module.scss */
	.m-primary-nav {
		font-size: 1.165em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 16, scss/70-modules/navigation/_module.scss */
	.m-primary-nav {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 16, scss/70-modules/navigation/_module.scss */
	.m-primary-nav {
		font-size: 1em;
	}
}

/* line 39, scss/70-modules/navigation/_module.scss */
.m-primary-nav--center-aligned {
	text-align: center;
}

/* line 42, scss/70-modules/navigation/_module.scss */
.m-primary-nav--center-aligned .primary-nav__list {
	text-align: center;
}

/* line 47, scss/70-modules/navigation/_module.scss */
.m-primary-nav--right-aligned {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-primary-nav--right-aligned {
	text-align: left;
}

/* line 50, scss/70-modules/navigation/_module.scss */
.m-primary-nav--right-aligned .primary-nav__item--single > .primary-nav__link {
	padding-right: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-primary-nav--right-aligned .primary-nav__item--single > .primary-nav__link {
	padding-right: initial;
	padding-left: 10px;
}

/* line 55, scss/70-modules/navigation/_module.scss */
.m-primary-nav.is-ready {
	max-height: none;
}

/* line 59, scss/70-modules/navigation/_module.scss */
.m-primary-nav .icon {
	font-size: 0.834em;
	padding: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 59, scss/70-modules/navigation/_module.scss */
	.m-primary-nav .icon {
		font-size: 0.97161em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 59, scss/70-modules/navigation/_module.scss */
	.m-primary-nav .icon {
		font-size: 0.834em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 59, scss/70-modules/navigation/_module.scss */
	.m-primary-nav .icon {
		font-size: 0.834em;
	}
}

/* line 64, scss/70-modules/navigation/_module.scss */
.m-primary-nav .icon-menu {
	padding: 0 10px 0 0;
	font-size: 1.2em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-primary-nav .icon-menu {
	padding: 0 0 0 10px;
}

/* line 69, scss/70-modules/navigation/_module.scss */
.m-primary-nav .icon-menu else {
	font-size: 1em;
}

@media only screen and (max-width: 47.999em) {
	/* line 69, scss/70-modules/navigation/_module.scss */
	.m-primary-nav .icon-menu else {
		font-size: 1.165em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 69, scss/70-modules/navigation/_module.scss */
	.m-primary-nav .icon-menu else {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 69, scss/70-modules/navigation/_module.scss */
	.m-primary-nav .icon-menu else {
		font-size: 1em;
	}
}

/* line 75, scss/70-modules/navigation/_module.scss */
.primary-nav__list {
	list-style: none;
	position: relative;
	font-family: var(--font-family-text);
	color: rgb(var(--color-dynamic-header-text));
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 75, scss/70-modules/navigation/_module.scss */
	.primary-nav__list {
		text-align: left;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .primary-nav__list {
		text-align: right;
	}
	/* line 100, scss/70-modules/navigation/_module.scss */
	.m-primary-nav--right-aligned .primary-nav__list {
		text-align: right;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-primary-nav--right-aligned .primary-nav__list {
		text-align: left;
	}
	/* line 104, scss/70-modules/navigation/_module.scss */
	.primary-nav__list.is-double, .primary-nav__list.is-triple {
		width: 100% !important;
		max-height: 50vh;
		overflow-y: auto;
	}
}

/* line 115, scss/70-modules/navigation/_module.scss */
.primary-nav__list.is-double.opens-down, .primary-nav__list.is-triple.opens-down {
	border-left: 1px solid rgb(var(--color-global-border));
	border-right: 1px solid rgb(var(--color-global-border));
	margin-left: -1px;
	margin-right: -1px;
}

/* line 124, scss/70-modules/navigation/_module.scss */
.primary-nav__item {
	text-align: left;
	position: relative;
	color: rgb(var(--color-dynamic-header-text));
	vertical-align: top;
	box-sizing: border-box;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .primary-nav__item {
	text-align: right;
}

/* line 131, scss/70-modules/navigation/_module.scss */
.primary-nav__item .primary-nav__link-menu {
	cursor: pointer;
	background: none;
	border: 0;
}

/* line 138, scss/70-modules/navigation/_module.scss */
.primary-nav__item--single.is-single-menu {
	text-align: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .primary-nav__item--single.is-single-menu {
	text-align: right;
}

/* line 142, scss/70-modules/navigation/_module.scss */
.m-primary-nav--right-aligned .primary-nav__item--single:not(.is-active) {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-primary-nav--right-aligned .primary-nav__item--single:not(.is-active) {
	text-align: left;
}

/* line 147, scss/70-modules/navigation/_module.scss */
.o-list-inline > .primary-nav__item.is-active,
.m-primary-nav:not(.is-touch-device) .primary-nav__item.is-active,
.m-primary-nav:not(.is-touch-device) .primary-nav__item:hover, .primary-nav__item--single {
	background-color: rgb(var(--color-dynamic-background-accent));
}

/* line 157, scss/70-modules/navigation/_module.scss */
.o-list-inline > .primary-nav__item.is-active > .primary-nav__link,
.m-primary-nav:not(.is-touch-device) .primary-nav__item.is-active > .primary-nav__link,
.m-primary-nav:not(.is-touch-device) .primary-nav__item:hover > .primary-nav__link {
	color: rgb(var(--color-dynamic-primary-color));
}

/* line 174, scss/70-modules/navigation/_module.scss */
.is-touch-device .primary-nav__item--single > .primary-nav__link:hover,
.primary-nav__item--single > .primary-nav__link {
	color: rgb(var(--color-dynamic-header-text));
}

/* line 181, scss/70-modules/navigation/_module.scss */
.o-list-inline > .primary-nav__item.is-single-menu--above-all.is-active > .primary-nav__link {
	background: rgb(var(--color-global-primary));
}

/* line 189, scss/70-modules/navigation/_module.scss */
.o-list-inline > .primary-nav__item.is-single-menu--above-all.is-active > .primary-nav__link, .o-list-inline > .primary-nav__item.is-single-menu--above-all.is-active .is-close {
	color: rgb(var(--color-global-positive));
}

@keyframes highlight-fade {
	0% {
		background: rgb(var(--m-primary-highlight-color));
		color: rgb(var(--color-global-secondary));
	}
	100% {
		background: rgb(var(--color-global-secondary));
		color: rgb(var(--color-dynamic-background-accent));
	}
}

/* line 205, scss/70-modules/navigation/_module.scss */
.primary-nav__link {
	text-decoration: none;
	text-transform: uppercase;
	color: rgb(var(--color-dynamic-header-text));
	white-space: nowrap;
	padding: 6.66667px 20px;
	position: relative;
	display: block;
}

/* line 214, scss/70-modules/navigation/_module.scss */
.is-touch-device .has-children:not(.is-single-menu) .primary-nav__link {
	padding-right: 43.66667px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-touch-device .has-children:not(.is-single-menu) .primary-nav__link {
	padding-right: initial;
	padding-left: 43.66667px;
}

/* line 219, scss/70-modules/navigation/_module.scss */
.primary-nav__link .is-highlighted {
	animation: highlight-fade 2s ease-out;
}

/* line 223, scss/70-modules/navigation/_module.scss */
.is-touch-device .primary-nav__link:hover {
	color: rgb(var(--color-dynamic-header-text));
}

/* line 228, scss/70-modules/navigation/_module.scss */
.is-expand {
	font-size: 12px;
	font-size: 1rem;
	right: 0;
	display: flex;
	align-items: center;
	border: 1px solid rgb(var(--color-global-border));
	color: rgb(var(--color-global-secondary));
	position: absolute;
	top: 0;
	bottom: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-expand {
	right: auto;
	left: 0;
}

/* line 240, scss/70-modules/navigation/_module.scss */
.is-expand .icon {
	font-size: 0.834em;
	display: block;
}

@media only screen and (max-width: 47.999em) {
	/* line 240, scss/70-modules/navigation/_module.scss */
	.is-expand .icon {
		font-size: 0.97161em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 240, scss/70-modules/navigation/_module.scss */
	.is-expand .icon {
		font-size: 0.834em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 240, scss/70-modules/navigation/_module.scss */
	.is-expand .icon {
		font-size: 0.834em;
	}
}

/* line 245, scss/70-modules/navigation/_module.scss */
.is-focus > .primary-nav__link .is-expand {
	background: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-positive));
	border-color: rgb(var(--color-global-primary));
	border-left-color: rgb(var(--color-global-border));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-focus > .primary-nav__link .is-expand {
	border-left-color: none;
	border-right-color: rgb(var(--color-global-border));
}

/* line 251, scss/70-modules/navigation/_module.scss */
.is-focus > .primary-nav__link .is-expand .icon {
	transform: rotate(180deg);
	transition-duration: 0.3s;
}

/* line 260, scss/70-modules/navigation/_module.scss */
.opens-down,
.opens-right,
.opens-left,
.is-more-list {
	z-index: 2;
	visibility: hidden;
	position: absolute;
	background: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-border));
}

/* line 271, scss/70-modules/navigation/_module.scss */
.opens-down:not(.is-dropdown),
.opens-right:not(.is-dropdown),
.opens-left:not(.is-dropdown),
.is-more-list:not(.is-dropdown) {
	box-shadow: 0 5px 30px -5px rgb(var(--color-global-border-50));
}

/* line 276, scss/70-modules/navigation/_module.scss */
.opens-down .primary-nav__item:not(:first-child),
.opens-right .primary-nav__item:not(:first-child),
.opens-left .primary-nav__item:not(:first-child),
.is-more-list .primary-nav__item:not(:first-child) {
	border-top: 1px solid rgb(var(--color-global-border));
}

/* line 281, scss/70-modules/navigation/_module.scss */
.is-touch-device .opens-down .primary-nav__item .primary-nav__item:not(:first-child), .is-touch-device
.opens-right .primary-nav__item .primary-nav__item:not(:first-child), .is-touch-device
.opens-left .primary-nav__item .primary-nav__item:not(:first-child), .is-touch-device
.is-more-list .primary-nav__item .primary-nav__item:not(:first-child) {
	border-top: 1px solid rgb(var(--color-global-border));
}

/* line 286, scss/70-modules/navigation/_module.scss */
.opens-down .primary-nav__link,
.opens-right .primary-nav__link,
.opens-left .primary-nav__link,
.is-more-list .primary-nav__link {
	text-transform: none;
	padding: 10px 20px;
	min-width: 130px;
}

/* line 291, scss/70-modules/navigation/_module.scss */
.paw-header .opens-down .primary-nav__link, .paw-header
.opens-right .primary-nav__link, .paw-header
.opens-left .primary-nav__link, .paw-header
.is-more-list .primary-nav__link {
	color: rgb(var(--color-dynamic-primary-color));
}

@media only screen and (max-width: 59.999em) {
	/* line 286, scss/70-modules/navigation/_module.scss */
	.opens-down .primary-nav__link,
	.opens-right .primary-nav__link,
	.opens-left .primary-nav__link,
	.is-more-list .primary-nav__link {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

/* line 301, scss/70-modules/navigation/_module.scss */
.opens-down .is-expand,
.opens-right .is-expand,
.opens-left .is-expand,
.is-more-list .is-expand {
	border-right-width: 0;
	border-top-color: transparent;
	border-bottom-color: transparent;
	bottom: -1px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .opens-down .is-expand, .is-rtl
.opens-right .is-expand, .is-rtl
.opens-left .is-expand, .is-rtl
.is-more-list .is-expand {
	border-right-width: 0;
	border-left-width: 0;
}

/* line 309, scss/70-modules/navigation/_module.scss */
.opens-down .is-focus .is-expand,
.opens-down .is-focus .primary-nav__link,
.opens-right .is-focus .is-expand,
.opens-right .is-focus .primary-nav__link,
.opens-left .is-focus .is-expand,
.opens-left .is-focus .primary-nav__link,
.is-more-list .is-focus .is-expand,
.is-more-list .is-focus .primary-nav__link {
	color: rgb(var(--color-global-positive));
}

/* line 314, scss/70-modules/navigation/_module.scss */
.opens-down .is-focus > .primary-nav__link,
.opens-right .is-focus > .primary-nav__link,
.opens-left .is-focus > .primary-nav__link,
.is-more-list .is-focus > .primary-nav__link {
	color: inherit;
}

/* line 318, scss/70-modules/navigation/_module.scss */
.opens-down .is-focus .is-expand,
.opens-down .is-focus .primary-nav__item,
.opens-right .is-focus .is-expand,
.opens-right .is-focus .primary-nav__item,
.opens-left .is-focus .is-expand,
.opens-left .is-focus .primary-nav__item,
.is-more-list .is-focus .is-expand,
.is-more-list .is-focus .primary-nav__item {
	background-color: rgb(var(--color-global-secondary));
}

/* line 324, scss/70-modules/navigation/_module.scss */
.opens-down .icon,
.opens-right .icon,
.opens-left .icon,
.is-more-list .icon {
	padding: 14px;
}

/* line 329, scss/70-modules/navigation/_module.scss */
.opens-down {
	border: 0;
}

/* line 333, scss/70-modules/navigation/_module.scss */
.has-scroll {
	overflow-y: auto;
}

/* line 337, scss/70-modules/navigation/_module.scss */
.is-more-list {
	top: 100%;
	transition: 0.2s 0.6s;
	right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-more-list {
	right: auto;
	left: 0;
}

/* line 348, scss/70-modules/navigation/_module.scss */
.is-more-list > .opens-down,
.is-more-list > .opens-right,
.is-more-list > .opens-left {
	visibility: hidden;
}

/* line 354, scss/70-modules/navigation/_module.scss */
.is-more-list .primary-nav__item {
	display: block;
	width: 100%;
}

/* line 362, scss/70-modules/navigation/_module.scss */
.is-active > .is-more-list {
	visibility: visible;
	transition-delay: 0s;
}

/* line 369, scss/70-modules/navigation/_module.scss */
.opens-down {
	top: 100%;
	min-width: 100%;
}

/* line 374, scss/70-modules/navigation/_module.scss */
.is-active > .opens-down {
	visibility: visible;
}

/* line 381, scss/70-modules/navigation/_module.scss */
.is-active > .opens-right,
.is-active > .opens-left {
	visibility: visible;
}

/* line 386, scss/70-modules/navigation/_module.scss */
.is-active > .is-dropdown {
	height: auto;
	transition: 0.2s 0.8s;
}

/* line 390, scss/70-modules/navigation/_module.scss */
.m-primary-nav:not(.is-touch-device) .is-active > .is-dropdown {
	max-height: 100vh;
}

/* line 396, scss/70-modules/navigation/_module.scss */
.opens-right,
.opens-left {
	top: -1px;
}

/* line 402, scss/70-modules/navigation/_module.scss */
.opens-right {
	left: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .opens-right {
	left: auto;
	right: 100%;
}

/* line 406, scss/70-modules/navigation/_module.scss */
.opens-left {
	right: 100%;
}

/* line 410, scss/70-modules/navigation/_module.scss */
.is-dropdown {
	position: relative;
	left: auto;
	right: auto;
	top: 0;
	transition: 0.1s 0.4s;
	border-width: 1px 0 0 0;
	height: 0;
	overflow: hidden;
}

/* line 416, scss/70-modules/navigation/_module.scss */
.m-primary-nav:not(.is-touch-device) .is-dropdown {
	max-height: 0;
}

/* line 425, scss/70-modules/navigation/_module.scss */
.is-touch-device .is-dropdown .primary-nav__link:before {
	padding-right: 10px;
	content: '\2022';
	display: inline-block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-touch-device .is-dropdown .primary-nav__link:before {
	padding-right: initial;
	padding-left: 10px;
}

/* line 433, scss/70-modules/navigation/_module.scss */
.is-dropdown[data-level="1"] .primary-nav__link {
	padding-left: 30px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-dropdown[data-level="1"] .primary-nav__link {
	padding-left: initial;
	padding-right: 30px;
}

/* line 433, scss/70-modules/navigation/_module.scss */
.is-dropdown[data-level="2"] .primary-nav__link {
	padding-left: 40px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-dropdown[data-level="2"] .primary-nav__link {
	padding-left: initial;
	padding-right: 40px;
}

/* line 433, scss/70-modules/navigation/_module.scss */
.is-dropdown[data-level="3"] .primary-nav__link {
	padding-left: 50px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-dropdown[data-level="3"] .primary-nav__link {
	padding-left: initial;
	padding-right: 50px;
}

/* line 440, scss/70-modules/navigation/_module.scss */
.is-single-menu--above-all {
	width: 100%;
}

/* line 443, scss/70-modules/navigation/_module.scss */
.is-single-menu--above-all.is-active {
	z-index: 9999999;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

/* line 453, scss/70-modules/navigation/_module.scss */
.is-single-menu--above-all.is-active .is-more-list {
	z-index: 9999999;
	flex-grow: 1;
	top: auto;
	overflow-y: auto;
	position: static;
	box-shadow: none;
}

/* line 462, scss/70-modules/navigation/_module.scss */
.is-single-menu--above-all.is-active > .primary-nav__link {
	flex: 0 0 auto;
}

/* line 466, scss/70-modules/navigation/_module.scss */
.is-single-menu--above-all.is-active .is-more-list {
	min-width: 100vw;
}

/* line 470, scss/70-modules/navigation/_module.scss */
.is-single-menu--above-all.is-active .icon-menu {
	visibility: hidden;
}

/* line 476, scss/70-modules/navigation/_module.scss */
.is-single-menu .is-more-list {
	text-align: left;
	left: 0;
	min-width: 100%;
	transition: 0s 0s;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single-menu .is-more-list {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single-menu .is-more-list {
	left: auto;
	right: 0;
}

/* line 482, scss/70-modules/navigation/_module.scss */
.is-single-menu .is-more-list > .primary-nav__item:last-child {
	border-bottom: 1px solid rgb(var(--color-global-border));
}

/* line 487, scss/70-modules/navigation/_module.scss */
.is-single-menu > .primary-nav__link {
	padding-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single-menu > .primary-nav__link {
	padding-left: initial;
	padding-right: 10px;
}

/* line 493, scss/70-modules/navigation/_module.scss */
.is-touch-device .is-single-menu--as-children.primary-nav__item {
	width: 100%;
}

/* line 497, scss/70-modules/navigation/_module.scss */
.is-single-menu--as-children .is-more-list {
	min-width: 0;
}

/* line 501, scss/70-modules/navigation/_module.scss */
.is-single-menu--as-children .is-close {
	left: 10px;
	right: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single-menu--as-children .is-close {
	left: auto;
	right: 10px;
}

/* line 505, scss/70-modules/navigation/_module.scss */
.is-single-menu--as-children > .primary-nav__link {
	padding-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single-menu--as-children > .primary-nav__link {
	padding-left: initial;
	padding-right: 20px;
}

/* line 511, scss/70-modules/navigation/_module.scss */
.is-single-menu.is-active.is-single-menu--above-all .is-close {
	display: inline-block;
}

/* line 516, scss/70-modules/navigation/_module.scss */
.is-single-menu .is-close {
	top: 50%;
	transform: translate(-0, -50%);
	left: 0;
	color: rgb(var(--color-global-secondary));
	padding: 10px;
	display: none;
	position: absolute;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single-menu .is-close {
	left: auto;
	right: 0;
}

/* line 528, scss/70-modules/navigation/_module.scss */
.has-border--top .primary-nav__item .primary-nav__item, .has-border--bottom .primary-nav__item .primary-nav__item {
	padding: 0 20px;
}

/* line 531, scss/70-modules/navigation/_module.scss */
.has-border--top .primary-nav__item .primary-nav__item .primary-nav__link, .has-border--bottom .primary-nav__item .primary-nav__item .primary-nav__link {
	border-width: 1px 0;
}

/* line 538, scss/70-modules/navigation/_module.scss */
.has-border--top .is-more-list .primary-nav__item, .has-border--bottom .is-more-list .primary-nav__item {
	padding: 0;
}

/* line 551, scss/70-modules/navigation/_module.scss */
.has-border--top .primary-nav__link {
	border-top: 2px solid transparent;
}

/* line 556, scss/70-modules/navigation/_module.scss */
.has-border--top .is-active .primary-nav__link {
	border-top-color: rgb(var(--color-global-primary));
}

/* line 561, scss/70-modules/navigation/_module.scss */
.has-border--top .is-active .primary-nav__item:first-child .primary-nav__link {
	border-top-color: transparent;
}

/* line 569, scss/70-modules/navigation/_module.scss */
.has-border--bottom .primary-nav__link:not(.primary-nav__link-menu) {
	border-bottom: 2px solid transparent;
}

/* line 574, scss/70-modules/navigation/_module.scss */
.has-border--bottom .is-active .primary-nav__link {
	border-bottom-color: rgb(var(--color-global-primary));
}

/* line 579, scss/70-modules/navigation/_module.scss */
.has-border--bottom .is-active .primary-nav__item:last-child .primary-nav__link {
	border-bottom-color: transparent;
}

/* line 587, scss/70-modules/navigation/_module.scss */
.has-borders {
	border: 1px solid rgb(var(--color-global-border));
	border-width: 1px 0;
}

/* line 591, scss/70-modules/navigation/_module.scss */
.has-borders .o-list-inline > .primary-nav__item:before {
	transform: translateY(-50%);
	border-right: 1px solid rgb(var(--color-global-border));
	left: 0;
	position: absolute;
	z-index: -1;
	top: 50%;
	height: 20px;
	content: '';
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .has-borders .o-list-inline > .primary-nav__item:before {
	border-right: 0;
	border-left: 1px solid rgb(var(--color-global-border));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .has-borders .o-list-inline > .primary-nav__item:before {
	left: auto;
	right: 0;
}

/* line 604, scss/70-modules/navigation/_module.scss */
.m-primary-nav--flyout {
	padding: 5px 0 5px 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-primary-nav--flyout {
	padding: 5px 0 5px 0;
}

/* line 609, scss/70-modules/navigation/_module.scss */
.m-primary-nav--flyout .primary-nav__item .is-more-list.opens-down.js-nav-list {
	position: fixed;
	top: 0;
	right: 0 !important;
	min-height: 100% !important;
	max-width: 0 !important;
	min-width: 0 !important;
	width: 0;
	left: unset !important;
	z-index: 99;
	transition: all .3s;
	list-style: none;
}

/* line 625, scss/70-modules/navigation/_module.scss */
.m-primary-nav--flyout .primary-nav__item .is-active > .primary-nav__list.opens-left,
.m-primary-nav--flyout .primary-nav__item .is-active > .primary-nav__list.opens-right {
	left: 0;
	right: 0;
	top: 100%;
}

/* line 634, scss/70-modules/navigation/_module.scss */
.m-primary-nav--flyout .primary-nav__item--close-hamburger {
	cursor: pointer;
}

/* line 638, scss/70-modules/navigation/_module.scss */
.m-primary-nav--flyout .icon-menu {
	font-size: 1.167em;
}

@media only screen and (max-width: 47.999em) {
	/* line 646, scss/70-modules/navigation/_module.scss */
	.m-primary-nav--flyout .is-single-menu.is-active.is-single-menu--above-all .is-close {
		display: none;
	}
	/* line 650, scss/70-modules/navigation/_module.scss */
	.m-primary-nav--flyout .o-list-inline > .primary-nav__item.is-active,
	.m-primary-nav--flyout .primary-nav__item--single {
		background-color: transparent;
	}
}

/* line 658, scss/70-modules/navigation/_module.scss */
.m-primary-nav--flyout .primary-nav__item--single .primary-nav__icon-text {
	display: none;
}

/* line 664, scss/70-modules/navigation/_module.scss */
.m-primary-nav--flyout.is-submenu-opened-on-click .is-more-list.opens-down .primary-nav__item:not(.is-active) > .primary-nav__list.opens-left,
.m-primary-nav--flyout.is-submenu-opened-on-click .is-more-list.opens-down .primary-nav__item:not(.is-active) > .primary-nav__list.opens-right {
	display: none;
}

/* line 669, scss/70-modules/navigation/_module.scss */
.m-primary-nav--flyout.is-submenu-opened-on-click .is-more-list.opens-down .primary-nav__item > .primary-nav__list.opens-left,
.m-primary-nav--flyout.is-submenu-opened-on-click .is-more-list.opens-down .primary-nav__item > .primary-nav__list.opens-right {
	position: relative;
}

/* line 678, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger.is-left .m-primary-nav .primary-nav__item .is-more-list.opens-down.js-nav-list {
	left: 0;
}

/* line 684, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger.is-right .m-primary-nav .primary-nav__item .is-more-list.opens-down.js-nav-list {
	right: 0;
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 676, scss/70-modules/navigation/_module.scss */
	.m-navigation--with-hamburger {
		padding-left: 20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-navigation--with-hamburger {
		padding-left: initial;
		padding-right: 20px;
	}
	/* line 691, scss/70-modules/navigation/_module.scss */
	.paw-header--mobile-first .m-navigation--with-hamburger {
		padding-left: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .paw-header--mobile-first .m-navigation--with-hamburger {
		padding-left: initial;
		padding-right: 0;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 696, scss/70-modules/navigation/_module.scss */
	.m-navigation--with-hamburger .m-primary-nav {
		padding: 5px 0 5px 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-navigation--with-hamburger .m-primary-nav {
		padding: 5px 0 5px 0;
	}
}

/* line 708, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger .m-primary-nav .primary-nav__item .is-more-list.opens-down.js-nav-list {
	position: fixed;
	top: 0;
	min-height: 100% !important;
	min-width: 0 !important;
	max-width: 0;
	width: 0;
	left: unset !important;
	z-index: 99;
	transition: all .3s;
	list-style: none;
	overflow: auto;
	height: 100%;
}

/* line 725, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger .m-primary-nav .primary-nav__item .primary-nav__link-menu + .is-more-list.opens-down {
	max-width: 0 !important;
}

@media only screen and (max-width: 59.999em) {
	/* line 731, scss/70-modules/navigation/_module.scss */
	.global-header--mobile-first .m-navigation--with-hamburger .m-primary-nav .primary-nav__item .is-active > .primary-nav__list.opens-left,
	.global-header--mobile-first .m-navigation--with-hamburger .m-primary-nav .primary-nav__item .is-active > .primary-nav__list.opens-right {
		left: 0;
		right: 0;
		top: 0;
	}
}

/* line 742, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger .m-primary-nav .primary-nav__item--close-hamburger {
	cursor: pointer;
}

/* line 746, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger .m-primary-nav .icon-menu {
	font-size: 1.167em;
}

@media only screen and (max-width: 47.999em) {
	/* line 754, scss/70-modules/navigation/_module.scss */
	.m-navigation--with-hamburger .m-primary-nav .is-single-menu.is-active.is-single-menu--above-all .is-close {
		display: none;
	}
	/* line 758, scss/70-modules/navigation/_module.scss */
	.m-navigation--with-hamburger .m-primary-nav .o-list-inline > .primary-nav__item.is-active,
	.m-navigation--with-hamburger .m-primary-nav .primary-nav__item--single {
		background-color: transparent;
	}
	/* line 763, scss/70-modules/navigation/_module.scss */
	.m-navigation--with-hamburger .m-primary-nav .icon-menu {
		padding-left: 10px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-navigation--with-hamburger .m-primary-nav .icon-menu {
		padding-left: initial;
		padding-right: 10px;
	}
	/* line 766, scss/70-modules/navigation/_module.scss */
	.paw-header--mobile-first .m-navigation--with-hamburger .m-primary-nav .icon-menu {
		padding-left: 0;
		position: relative;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .paw-header--mobile-first .m-navigation--with-hamburger .m-primary-nav .icon-menu {
		padding-left: initial;
		padding-right: 0;
	}
}

@media only screen and (max-width: 340px) {
	/* line 774, scss/70-modules/navigation/_module.scss */
	.m-navigation--with-hamburger .m-primary-nav .icon-menu {
		padding-left: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-navigation--with-hamburger .m-primary-nav .icon-menu {
		padding-left: initial;
		padding-right: 0;
	}
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 786, scss/70-modules/navigation/_module.scss */
	.paw-header--mobile-first .m-navigation--with-hamburger .m-primary-nav > .o-list-inline.primary-nav__list {
		right: unset;
	}
}

/* line 793, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger .primary-nav__item--single {
	background-color: transparent;
}

/* line 796, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger .primary-nav__item--single .primary-nav__icon-text {
	display: none;
}

/* line 800, scss/70-modules/navigation/_module.scss */
.m-navigation--with-hamburger .primary-nav__item--single.is-single-menu > .primary-nav__link {
	padding-left: 13px;
	padding-top: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-navigation--with-hamburger .primary-nav__item--single.is-single-menu > .primary-nav__link {
	padding-left: initial;
	padding-right: 13px;
}

@media only screen and (min-width: 48em) {
	/* line 800, scss/70-modules/navigation/_module.scss */
	.m-navigation--with-hamburger .primary-nav__item--single.is-single-menu > .primary-nav__link {
		padding-left: 20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-navigation--with-hamburger .primary-nav__item--single.is-single-menu > .primary-nav__link {
		padding-left: initial;
		padding-right: 20px;
	}
}

/* line 811, scss/70-modules/navigation/_module.scss */
.paw-header--mobile-first .m-navigation--with-hamburger .primary-nav__item--single.is-single-menu > .primary-nav__link {
	padding: 0;
}

@media only screen and (max-width: 29.999em) {
	/* line 825, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--palm {
		display: none;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 825, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--palm-wide {
		display: none;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 825, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--lap {
		display: none;
	}
}

@media only screen and (max-width: 79.999em) {
	/* line 825, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--lap-wide {
		display: none;
	}
}

@media only screen and (max-width: 104.999em) {
	/* line 825, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--desk {
		display: none;
	}
}

@media only screen and (min-width: 30em) {
	/* line 837, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--after-palm {
		display: none;
	}
}

@media only screen and (min-width: 48em) {
	/* line 837, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--after-palm-wide {
		display: none;
	}
}

@media only screen and (min-width: 60em) {
	/* line 837, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--after-lap {
		display: none;
	}
}

@media only screen and (min-width: 80em) {
	/* line 837, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--after-lap-wide {
		display: none;
	}
}

@media only screen and (min-width: 105em) {
	/* line 837, scss/70-modules/navigation/_module.scss */
	body:not(.show-preview-containers) .nav-item-hide--after-desk {
		display: none;
	}
}

/* line 8, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.global-header {
	background-color: rgb(var(--color-global-positive));
	border-bottom-color: rgb(var(--color-global-border));
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

/* line 15, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.primary-nav__link {
	color: rgb(var(--color-dynamic-header-text));
	padding: 10px;
}

/* line 19, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.primary-nav__link:hover {
	color: rgb(var(--color-dynamic-section-title));
	background-color: rgb(var(--color-dynamic-background-accent));
}

/* line 25, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.is-single-menu > .primary-nav__link {
	padding-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single-menu > .primary-nav__link {
	padding-left: initial;
	padding-right: 20px;
}

/* line 30, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.is-more-menu .primary-nav__item--close-hamburger {
	display: none;
}

/* line 36, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-navigation--with-hamburger .m-primary-nav .primary-nav__item--single > .primary-nav__link {
	color: rgb(var(--color-dynamic-header-text));
}

/* line 40, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-navigation--with-hamburger .m-primary-nav .is-more-list {
	background-color: "";
}

/* line 43, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-navigation--with-hamburger .m-primary-nav .is-more-list .icon.icon-close {
	color: "";
}

/* line 47, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-navigation--with-hamburger .m-primary-nav .is-more-list .is-expand {
	color: rgb(var(--color-dynamic-header-text));
}

/* line 52, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-navigation--with-hamburger .m-primary-nav .is-more-list .is-focus .is-expand {
	background-color: transparent;
}

@media only screen and (min-width: 60em) {
	/* line 60, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
	.m-navigation--with-hamburger .m-primary-nav .primary-nav__item.is-active .is-more-list.opens-down.js-nav-list {
		position: absolute;
		top: 100%;
		max-width: unset;
		width: auto;
		overflow: unset;
		transition: all 0s;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 35, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
	.m-navigation--with-hamburger .m-primary-nav {
		padding: 0 0 0 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-navigation--with-hamburger .m-primary-nav {
		padding: 0 0 0 0;
	}
	/* line 74, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
	.m-navigation--with-hamburger .m-primary-nav:hover {
		background-color: rgb(var(--color-dynamic-background-accent));
		color: rgb(var(--color-global-secondary));
		border-radius: 4px;
	}
	/* line 83, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
	.m-navigation--with-hamburger .m-primary-nav .primary-nav__item .is-more-list.opens-down.js-nav-list {
		bottom: 0;
		min-height: 100vh;
	}
}

/* line 91, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-navigation--with-hamburger .m-primary-nav .icon-menu {
	padding: 0 0 0 0;
	line-height: 32px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-navigation--with-hamburger .m-primary-nav .icon-menu {
	padding: 0 0 0 0;
}

/* line 97, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-navigation--with-hamburger .primary-nav__item--single.is-single-menu > .primary-nav__link.primary-nav__link-menu {
	padding: 0 0 0 0;
	float: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-navigation--with-hamburger .primary-nav__item--single.is-single-menu > .primary-nav__link.primary-nav__link-menu {
	padding: 0 0 0 0;
}

/* line 102, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.opens-down .primary-nav__link {
	color: rgb(var(--color-dynamic-header-text));
	background-color: transparent;
}

/* line 106, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.opens-down .primary-nav__link:hover {
	background-color: transparent;
	color: rgb(var(--color-dynamic-header-text));
	border-top: 1px solid rgba(var(--color-global-positive), .2);
}

/* line 114, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-primary-nav:not(.is-touch-device) .primary-nav__item:hover {
	background-color: rgb(var(--color-dynamic-background-accent));
}

/* line 118, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.m-primary-nav:not(.is-touch-device) .primary-nav__item:hover > .primary-nav__link {
	color: rgb(var(--color-dynamic-section-title));
}

/* line 125, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.paw-header .m-navigation--with-hamburger .m-primary-nav .primary-nav__item--single > .primary-nav__link {
	color: rgb(var(--color-dynamic-header-text));
}

/* line 129, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.paw-header .m-navigation--with-hamburger .m-primary-nav .is-more-list .is-expand {
	color: rgb(var(--color-dynamic-header-text));
}

/* line 135, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.paw-header .is-more-list .primary-nav__link {
	color: rgb(var(--color-dynamic-header-text));
}

/* line 140, scss/80-themes/AffinityDefault/70-modules/navigation/module.scss */
.paw-header .is-more-list .is-focus .opens-left .primary-nav__link {
	color: rgb(var(--color-global-positive));
}

/*# sourceMappingURL=../../../../../true */